@import "../../shared/styles/variables.scss";

.title h1 {
	display: flex;
	font-size: 36px;
	font-weight: 700;
	background-image: linear-gradient(90deg, #d8baff 1%, #7c00ff 100%);
	-webkit-text-fill-color: transparent;
	-webkit-box-decoration-break: clone;
	background-clip: text;

	@media (max-width: 1120px) {
		font-size: 40px;
		width: 100%;
	}
}
.about-container {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 64px;
	padding: 64px;
	justify-content: center;
	min-height: 100vh;

	@media (max-width: 1120px) {
		padding: 16px;
	}

	.about-content {
		display: flex;
		justify-content: center;
		gap: 64px;

		article.describe {
			display: flex;
			flex-direction: column;
			gap: 32px;
			max-width: 700px;
			overflow: auto;

			strong {
				font-size: 18px;
				font-weight: 700;
				color: $main-white;
			}

			p {
				line-height: 32px;
				color: $secondary-dark;
				font-size: 18px;
				font-weight: 300;
			}
		}

		.picture {
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			min-width: 400px;
			min-height: 400px;
			max-width: 400px;
			max-height: 400px;
			img {
				width: 100%;
				height: 100%;
				border-radius: 8px;
			}
		}

		@media (max-width: 1120px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 32px;

			.picture {
				min-width: auto;
				min-height: auto;
				width: 100%;
				height: 100%;
			}
			.describe {
				width: 100%;
			}
		}
	}
}
