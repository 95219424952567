@import "../../shared/styles/variables.scss";

.contact-area {
	background: rgba(70, 66, 66, 0.048);
	backdrop-filter: blur(10px);
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 16px;
	padding: 32px 0;
	.email {
		font-size: 16px;
		word-break: break-all;
	}

	.social {
		display: flex;
		gap: 16px;
		align-items: center;
		justify-content: center;

		.linkedin,
		.github,
		.insta {
			cursor: pointer;
		}
	}
	.social p:nth-child(n) {
		color: $main-white;
		font-size: 16px;
		text-align: center;
	}
}

.iconSocial {
	height: 26px;
	width: 26px;
	margin-right: 24px;
	cursor: pointer;
}
