.projects-container {
	padding: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 64px;
	min-height: 100vh;

	@media (max-width: 768px) {
		padding: 16px;
	}
	.projects {
		display: flex;
		flex-wrap: wrap;
		gap: 32px;
		justify-content: center;
		.project-item {
			width: 400px;
			border-radius: 16px;
			padding: 24px;
			box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
			backdrop-filter: blur(8px);
			-webkit-backdrop-filter: blur(8px);
			border-left: 1px solid rgba(255, 255, 255, 0.1);
			border-right: 1px solid rgba(255, 255, 255, 0.1);
			border-top: 1px solid rgba(255, 255, 255, 0.1);
			border-bottom: 1px solid rgba(255, 255, 255, 0);
			height: 400px;
			display: flex;
			flex-direction: column-reverse;
			cursor: pointer;

			.icon {
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 16px;
				top: 0px;
				right: 0px;
				font-size: 26px;
				color: white;
				z-index: 2;
				box-sizing: border-box;
				margin-left: auto;
				background: transparent;
				opacity: 0;
				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transition: 0.5s;

					@media (max-width: 425px) {
						transform: translate(-50%, -240%);
					}
				}
			}

			.project-image {
				width: 100%;
				height: 100%;
				border-radius: 16px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 16px;
				}
			}

			.project-info {
				z-index: 2;
				display: flex;
				flex-direction: column;
				gap: 16px;
				background: transparent;
				height: 35%;
				padding: 12px 0;
				h1 {
					font-size: 20px;
					font-weight: 600;
				}
				p {
					font-size: 14px;
					font-weight: 400;
				}
			}

			.light {
				position: absolute;
				top: -1px;
				left: 200px;
				width: 40%;
				height: 1px;
				background: linear-gradient(
					355deg,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 0.1),
					rgba(255, 255, 255, 0.2),
					rgba(255, 255, 255, 0.4),
					rgba(255, 255, 255, 0.1),
					rgba(255, 255, 255, 0)
				);
				z-index: 2;
				opacity: 1;
			}

			.shadow {
				background-color: black;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 35%;
				box-shadow: 0 -2px 12px 18px rgb(1, 1, 1);
				opacity: 1;
				z-index: 1;
			}

			&:hover {
				transform: translateY(-10px);
				transition: 0.3s;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);

				.icon {
					opacity: 1;
					transition: 0.5s;
				}

				.shadow {
					height: 100%;
					transition: 0.8s;
					box-shadow: none;
					border-radius: 16px;
					opacity: 0.9;
				}

				.light {
					opacity: 0;
					transition: 0.8s;
				}
			}

			@media (max-width: 425px) {
				width: 300px;
				height: 450px;

				.light {
					left: 145px !important;
				}
			}
		}
	}
}
