@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./shared/styles/variables.scss";

html,
body {
	background-color: #000000;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: #fff;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-variant: normal;
	letter-spacing: 0px;
	font-weight: 300;

	&::-webkit-scrollbar {
		width: 10px !important;
	}
	&::-webkit-scrollbar-track {
		background: $main-white !important;
		border-radius: 100px !important;
	}
	&::-webkit-scrollbar-thumb {
		background: $soft-dark !important;
		border-radius: 100px !important;
	}
}

.title h1 {
	display: flex;
	font-size: 36px;
	font-weight: 700;
	background-image: linear-gradient(90deg, #d8baff 1%, #7c00ff 100%);
	-webkit-text-fill-color: transparent;
	-webkit-box-decoration-break: clone;
	background-clip: text;

	@media (max-width: 1120px) {
		font-size: 30px;
	}
}

.App {
	//margin: 0px 120px 0px 120px;
	display: flex;
	flex-direction: column;
	@media (max-width: 768px) {
		justify-content: center;
	}
}

h1 {
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
strong,
li,
button {
	background: transparent;
}
